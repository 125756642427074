textarea, input[type=text], input[type=email] {line-height:22px; color:#666;}
input[type=text] {height:22px;}

body {color:#808080; font-size:13px; line-height:24px; font-family:Helvetica, Arial, sans-serif; }
body {margin: 0 auto; width: 1200px;}

body {background:#ebebeb none repeat fixed; background-image:none; }


h1,h2,h3,h4,h5,h6 {font-weight:400; font-family:Helvetica, Arial, sans-serif; color:#000000; margin:0 0 13px 0; text-transform:none; }



h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {color:#000000; }

a {color:#a9a9a9; text-decoration:none; outline:none;}
a:hover {text-decoration:none; color:#5c5c5c; }
a:active {outline:none;}

h1 {font-size:48px;}
h2 {font-size:32px;}
h3 {font-size:24px;}
h4 {font-size:20px;}
h5 {font-size:16px;}

table th {text-align:left; font-size:12px; font-family:"Georgia", serif; font-style:italic; font-weight:400; text-transform:lowercase;}
table tr td {padding:5px 10px; vertical-align:middle;}
table tr td:first-child {padding-left:0px;}
table tr td:last-child {padding-right:0px;}

input, a {outline:none !important;}
input:hover, a:hover {outline:none !important;}
input:active, a:active {outline:none !important;}
input[type=submit], button, a.btn {border:none; outline:none; padding:10px 25px; color:#fff; background:#000000; font-size:12px; font-style:italic; text-transform:lowercase; font-family:"Georgia", serif;}
input[type=submit]:hover, button:hover, a.btn:hover {background:#222222}

header {padding:0;}
header h1 {margin:0;}
header h1 a:hover {color:#000000;}
header div.logo {display:none;}
header div.logo-new {float:left;}
header .logo a, header .logo a img {display:block; margin:0; padding:0;}

header div.cart-summary {
    /* position:absolute; left:60%;*/
    float:right; width:200px; text-align:right; 
    font-size:13px; text-transform:uppercase; 
    font-weight:400; color:#aaaaaa; z-index:20000 !important;}
header div.cart-summary span.cart a {
    display:block; float:right; height:26px; margin-top:5px; padding-left:36px; background:url('cart-icon.png') repeat-y 0px 0px; 
    font-size:13px; font-style:italic; text-transform:lowercase; font-family:"Georgia", serif; margin: 5px 0 0 0;}
#cart-updated {
    position:absolute; top:100%; background:url('details-bg.png') no-repeat 50% 0; padding:10px 0 5px 0; 
    z-index:15000 !important; text-align:center; color:#fff; font-size:12px; text-transform:none; line-height:16px; display:none;}
#cart-updated a {color:#fff; font-family:"Georgia", serif; font-style:italic; text-transform:lowercase;}

/* The contact info has been hidden (display:none) */
header .contact-info {
    display:none;text-align:right; font-size:12px; /* position:absolute; top:3px; left:60%; */ width:300px; 
    font-style:italic; text-transform:lowercase; font-family:"Georgia", serif; float:right; margin-top:4px}
header .contact-info span {margin-left:10px;}

nav.main {margin-bottom:20px;}
nav li a {padding:5px 10px; font-size:13px; text-transform:uppercase; font-weight:400; color:#aaaaaa; }
nav li a:hover {color:#555555;}
nav li {margin:0; float:left;}
nav li a.current, nav li.active a {color:#555555; background:#dedede; }

input[type=text] {border:1px solid #d8d8d8; padding:0px 10px; border-radius:18px; -moz-border-radius:18px; -webkit-border-radius:18px;}
.c input[type=text] {text-align:center;}

.scroll {overflow:hidden; width:952px;}
.slider {display:none; position:relative; margin:3px 0 0 0; width:100%; background:#d9d9d9; border:none; height:24px; overflow:hidden; z-index:100;}
.slider span {z-index:150 !important; display:block; height:24px; line-height:24px; color:#aaa; padding:0 10px; position:absolute; top:0px;}
.slider span:hover {color:#333;}
.slider span.prev {left:0px;}
.slider span.next {right:0px;}
.ui-slider-handle {display:block; cursor:pointer; height:24px; top:0; width:100px; background:#666;}
.ui-slider-handle:hover {background:#000;}

#body {padding:0;}
#body .image a, #body .image a:hover {display:block; border-bottom:none;}


#body .main {float:left; width:626px;}
#body .sidebar {float:right; width:292px;}


#body .one {width:292px; margin-right:42px;}
#body .two {width:292px;}

#body ul {list-style:square inside none;}

.sidebar .summary td {padding:10px 0;}
tr.summary {border-bottom:none;}
.sidebar .total {font-weight:700;}

.pagination span.current, .pagination a {display:block; float:left; padding:2px 6px;}
.pagination span.current {text-decoration:none; font-weight:700; color:#000;}
.pagination a {color:#888; text-decoration:none; font-weight:400; border-bottom:none;}
.pagination a:hover {color:#666;}

input.disabled {opacity:0.5;}

.loading {padding:200px 0; font-size:11px; font-style:italic; font-family:"Georgia", serif; text-align:center;}

.more-info {padding:0 0 5px 0; margin:0 0 3px 0; font-size:12px; line-height:18px; font-family:"Georgia", serif; border:none;}
.more-info .lower {display:block; color:#999; text-transform:lowercase; font-style:italic;}
.more-info .upper {color:#222; display:block; text-transform:uppercase; margin-bottom:-2px; font-family:Helvetica, Arial, sans-serif;}

/* dropdown */
nav li {position:relative; z-index:12000 !important;}
nav li.dropdown > a {padding-right:20px; margin-right:5px; background:url('nav-dropdown.png') no-repeat 93% 50%;}
nav li.dropdown > a.current { background:#dedede url('nav-dropdown.png') no-repeat 93% 50%; color:#555555;}
nav li ul {z-index:10000 !important; position:absolute; text-align:center; min-width:100%; top:100%; display:none; opacity:0.0; background:url('details-bg.png') no-repeat 50% 0; padding:7px 0 3px 0;}
nav li ul li, nav li ul li a {display:block; margin:0; padding:0; float:none;}
nav li ul li a {padding:3px 10px; text-transform:uppercase; font-size:12px; line-height:18px; color:#fff;}
nav li ul li a.current {background:none !important; color:#fff !important;}
nav li ul li a:hover {color:#aaa;}

footer {padding:40px 0 120px 20px;}
footer .more-info {margin-bottom:5px;}
footer .search {float:right;}
footer li {margin:0;}
footer li a {margin-right:25px;}
footer nav, footer .search {float:left; width:500px; margin-right:16px;}
footer .search, footer nav.last {margin-right:0;}
footer nav li {float:left; display:block; font-size:12px; line-height:22px; font-family:"Georgia", serif; font-style:italic; text-transform:lowercase !important;}
footer nav li a {float:left; display:block; padding:0 10px; margin:0; text-transform:lowercase !important; font-size:12px;}
footer nav li a.current {background:none !important;}

#featured-products {padding-top:40px;}

/* index */
.product a, .product img {display:block;}

.slider-container {padding:3px; margin-bottom:16px; position:relative; z-index:100 !important; display:none;}

#slider li, #slider img {display:block; margin:0; padding:0;}
.bx-pager {position:absolute; top:100%; left:10px; margin-top:-20px;}
.bx-pager a {float:left; margin-right:2px; display:block; text-indent:-9999px; width:14px; height:15px; background:url('pager.png') no-repeat 0px 0px;}
.bx-pager a:hover, .bx-pager a.pager-active {background:url('pager.png') no-repeat 0px -16px;}

#content-slide a {border-bottom:none !important;}
#content-slide .product {float:left; opacity:1.0; position:relative; margin-right:3px;}
#content-slide .product:last-child, #content-slide .product.last {margin-right:0px;}
#content-slide .product .details {background:url('details-bg.png') no-repeat 50% 0; padding:0; position:absolute; width:70%; left:15%; top:70%; opacity:0.0;}
#content-slide .product .details .title {text-align:center; padding:13px 10px 10px 10px; display:block; color:#fff; font-size:12px; line-height:18px; font-family:"Georgia", serif; font-style:italic;}
#content-slide .product.small, #content-slide .product.small .image, #content-slide .product.small .image img {max-width:190px;} /* make IE play nice */

#content-slide .product .details .extras {text-align:center; display:none; font-size:10px; font-family:"Helvetica", "Arial", sans-serif; font-style:italic; color:#aaa; background:#000; padding:0; background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0, rgb(0,0,0)),color-stop(1, rgb(32,32,32))); background-image: -moz-linear-gradient(center bottom,rgb(0,0,0) 0%,rgb(32,32,32) 100%);}
#content-slide .product.current .details .extras {display:block; border-top:1px solid #2c2c2c;}
#content-slide .product.current .details .extras span:hover {color:#fff;}
#content-slide .product.current .details .extras .description {float:left; display:block; padding-left:10px;}
#content-slide .product.current .details .extras .view {float:right; display:block; padding-right:10px;}

#content-slide .product.current .details {opacity:1.0;}
#content-slide .image a {display:block;}

#content-table a, #content-scroll a {border-bottom:none;}
#content-table .product {float:left; margin:0 13px 13px 0; width:190px; padding:5px; text-align:left; font-size:11px; position:relative; overflow:hidden;}
#content-table .product.last {margin-right:0;}
#content-table .product .image {min-height:230px; width:190px; text-align:center;}
#content-table .product .image, #content-table .product .image img {max-width:190px; margin:0 auto;height: 285px;}
#content-table .product .details {background:url('details-bg.png') no-repeat 50% 0; position:absolute; opacity:0.0; padding:0; position:absolute; width:70%; top:70%; left:15%; font-family:"Georgia", serif; font-style:italic;}
#content-table .product .details a {padding:13px 8px;}
#content-table .product .details span {line-height:18px; /* padding:0 10px; */}
#content-table .product .title {text-align:center; display:block; color:#fff; font-weight:400; font-size:12px; line-height:18px;}
#content-table .product .title a {color:#fff; font-weight:400; display:block;}

/* product */


#product .images {float:left;}
#product .content {float:right; width:355px;}


#product .select .selector-wrapper:last-child {margin-right:0;}
#product .selector-wrapper {margin-right:10px; float:left;}
#product .images .image {padding:3px;}
#product .images img {display:block;}

#product .images .featured {float:left;}
#product .images .thumbs {float:left;}

#product .images .featured img {max-width:472px;}
#product.one_image .content {width:455px;}
#product .images .thumbs .image {margin:0 0 13px 13px; min-height:80px; min-width:40px; text-align:center;}
#product .images .thumbs .image a {display:block;}
#product .images .thumbs .image img {max-width:80px; margin:0 auto;}
#product .product_body {margin-bottom:35px;}
#product .details {padding:13px 0; font-size:12px; font-family:"Georgia", serif; font-style:italic; text-transform:lowercase;}
#product .variants span.price {display:block; font-size:15px; padding:13px 0;}
#product .variants label {display:block; font-size:11px; text-transform:uppercase;}
#product .variants select {display:block;}
#product .variants .select {padding:10px; margin:16px 0 0 0; background:#FCFCFC;}
#product .price {float:left; margin: 0px;padding:10px 0; color:#000000; font-size:16px; }
#product .price span {color:#808080; font-size:14px; }
#product .purchase {}

#product .type, #product .vendor {margin-right:8px;}

/* collection */
#collection .details, #collection .description {padding-bottom:20px;}
#collection .controls {padding-bottom:20px;}
#collection .tags li {display:block; float:left; font-family:"Georgia", serif; font-size:12px; font-style:italic; padding:5px 15px 5px 0; text-transform:lowercase;}
#collection .tags li.active a {color:#000;}

/* cart */
#cart td {padding:10px;}
#cart .product_image a {display:block;}
#cart .product_image img {display:block; padding:3px;}
#cart .summary td {padding-top:30px;}

/* page */

/* blogs and articles */
#blog .article {padding:24px 0;}
#blog .articles .article:last-child {border-bottom:none;}
#blog .article .content p, #article .content p {margin-bottom:20px;}
#article .date, #article .back {font-size:11px; color:#c0c0c0;}
#article .comments .comment {padding:0 0 10px 0; margin-bottom:5px;}
#article .comments .comment .author {float:left; height:25px; line-height:25px; font-size:13px; color:#000;}
#article .comments .comment .gravatar {float:right; display:none;}
#article .comment_form input[type=text], #article .comment_form textarea {display:block; margin-bottom:10px; width:100%; border:1px solid #D9D9D9; padding:3px;}

/* search */
#search .highlight {background:#fff000; color:#000; padding:2px 4px;}
#search .results .item {padding:16px 0;}
#search .results .item:last-child {border-bottom:none;}
#search .results .item h3 {margin:0;}
#search .results .item .thumbnail {float:left; padding:3px; width:50px; text-align:center; margin-right:16px;}
#search .results .item .thumbnail a, #search .results .item .thumbnail img {display:block; margin:0 auto; padding:0;}
#search .results .item .content {float:left; width:552px;}

/* 404 */
#not_found {padding:100px 0 160px 0; text-align:center;}
#not_found h2 span {color:#aaa;}

/* cloud zoom */
.cloud-zoom-lens {background-color:#fff;}
.cloud-zoom-title { position:absolute !important; background-color:#000; color:#fff; padding:3px; width:100%; text-align:center; font-weight:bold; font-size:10px; top:0px;}
.cloud-zoom-big {overflow:hidden;}
.cloud-zoom-loading {color:white; background:#222; padding:3px; border:1px solid #000;}


/* css borders - in one spot to change easily */

table tr, nav.main, #sidebar .more-info, #product .collection-nav, #blog .article, #search .results .item {border-bottom:1px dotted #d8d8d8;}
nav.main {border-top:1px dotted #d8d8d8;}
#product .images .image, .slider-container, #content-table .product, #mens-list .product, #womens-list .product, #product .variants .select, #cart .product_image img, #search .results .item .thumbnail {background: none repeat scroll 0 0 #FFFFFF;
    border: 1px solid #D8D8D8;}


.additional-info { margin-bottom:40px; text-align:center; font-size:11px; }

.action_bottom { margin-top:20px; }
#customer, #recover-password { float:left; width:700px; }
#customer label, #recover-password label { display:block; }
#customer input[type=email], #recover-password input[type=email], #customer input[type=password], #recover-password input[type=password], #customer input[type=text], #recover-password input[type=text] { display:block; padding:3px; width:300px; }
#guest, #customer_sidebar, #order_address, #address_tables { margin:20px 0; float:left; width:260px; }
#customer_orders, #order_details, #add_address { margin:20px 0; float:right; width:700px; } 
#order_payment, #order_shipping { margin-bottom:20px; }
#address_tables td, #address_tables label, #address_tables input[type=text], #address_tables input[type=email] { display:block; margin:0; }
.edit_address { margin-bottom:20px; }
#address_tables tr, #address_tables tr td { padding:0px; border:none; }

.errors { color: #C00 }

/* New Styles */
#sidebar {
    float:right;
    width:300px;
    /* margin-right:15px;*/
    margin-top:-10px;
}

#body {
    width:880px;
    float:left;
    padding-left:5px; 
}

#mens-list, #womens-list {
    float:left;
    width:425px;
}

#mens-list {
    border-right:1px dotted #A1A1A1;
    /* padding-right:15px; */
    margin-right:15px;
}

.list-wrapper {
    float:left;
}

.list-wrapper h2 {
    text-align:center;
}

#mens-list a {border-bottom:none;}
#mens-list .product {float:left; margin:0 5px 5px 0; width:190px; padding:5px; text-align:left; font-size:11px; position:relative; overflow:hidden;}
#mens-list .product.last {/*margin-right:0;*/}
#mens-list .product .image {min-height:230px; width:190px; text-align:center;}
#mens-list .product .image, #mens-list .product .image img {max-width:190px; margin:0 auto;height:285px;}
#mens-list .product .details {background:url('details-bg.png') no-repeat 50% 0; position:absolute; opacity:0.0; padding:0; position:absolute; width:70%; top:70%; left:15%; font-family:"Georgia", serif; font-style:italic;}
#mens-list .product .details a {padding:13px 8px;}
#mens-list .product .details span {line-height:18px; /* padding:0 10px;*/ }
#mens-list .product .title {text-align:center; display:block; color:#fff; font-weight:400; font-size:12px; line-height:18px;}
#mens-list .product .title a {color:#fff; font-weight:400; display:block;}

#womens-list a {border-bottom:none;}
#womens-list .product {float:left; margin:0 5px 5px 0; width:190px; padding:5px; text-align:left; font-size:11px; position:relative; overflow:hidden;}
#womens-list .product.last {/*margin-right:0;*/}
#womens-list .product .image {min-height:230px; width:190px; text-align:center;}
#womens-list .product .image, #womens-list .product .image img {max-width:190px; margin:0 auto;height:285px;}
#womens-list .product .details {background:url('details-bg.png') no-repeat 50% 0; position:absolute; opacity:0.0; padding:0; position:absolute; width:70%; top:70%; left:15%; font-family:"Georgia", serif; font-style:italic;}
#womens-list .product .details a {padding:13px 8px;}
#womens-list .product .details span {line-height:18px; padding:0 10px;}
#womens-list .product .title {text-align:center; display:block; color:#fff; font-weight:400; font-size:12px; line-height:18px;}
#womens-list .product .title a {color:#fff; font-weight:400; display:block;}

#index #content-table, #index .more-info {
    display:none;
}

#sidebar .ad-space {
 margin:10px 0;
}

#sidebar .ad-space img {
     border:5px solid #ffffff;
}

#sidebar .ad-space a{
     background-color:#ffffff;
}

.seeAll {
    text-align: center;
    font-size: 24px;
    margin-top: 10px;
}

.seeAll a {
    color: #000000;
}

/* Mail Chimp Overrides - Newsletter Signup */
#mc_embed_signup {
    background:#fff; /*clear:left;*/ float: right; font:14px Helvetica,Arial,sans-serif;
    width: 230px; z-index:10000; /*position:absolute; top: 60px; left: 79%; */
    height: 156px; margin: -2px 0 12px 10px; border: 1px solid #D8D8D8;
}
#mc_embed_signup form {display:block; position:relative; text-align:right; /*padding:5px 0 10px 3% */}
#mc_embed_signup h2 {font-weight:bold; padding:0; margin:15px 0; font-size:1.4em;}
#mc_embed_signup input {border:1px solid #999; -webkit-appearance:none;}
#mc_embed_signup input[type=checkbox]{-webkit-appearance:checkbox;}
#mc_embed_signup input[type=radio]{-webkit-appearance:radio;}
#mc_embed_signup input:focus {border-color:#333;}
#mc_embed_signup .button {
    clear:both; background-color: #aaa; border: 0 none; border-radius:4px; color: #FFFFFF; cursor: pointer; 
    display: inline-block; font-size:12px; font-weight: bold; height: 30px; line-height: 30x; 
    margin: 0 20px 0px 0; padding:0; text-align: center; text-decoration: none; 
    vertical-align: top; white-space: nowrap; width: auto;}
#mc_embed_signup .button:hover {background-color:#777;}
#mc_embed_signup .small-meta {font-size: 11px;}
#mc_embed_signup .nowrap {white-space:nowrap;}     
#mc_embed_signup .clear {clear:none; display:inline;}

#mc_embed_signup label {display:block; font-size:14px; padding-bottom:5px; font-weight:bold; margin:25px 20px 5px 0px}
#mc_embed_signup input.email {
    display:block; padding:6px 0; margin:0 20px 10px 0;
    text-indent:5px; width:80%; min-width:130px; float:right}
#mc_embed_signup input.button {display:block; width:45%; /*margin:0 0 10px 0;*/ min-width:90px; float:right}

#mc_embed_signup div#mce-responses {
    float:left; top:-1.4em; padding:0em .5em 0em .5em; 
    overflow:hidden; width:90%;margin: 0 5%; clear: both;}
#mc_embed_signup div.response {
    margin:1em 0; padding:1em .5em .5em 0; font-weight:bold; 
    float:left; top:-1.5em; z-index:1; width:80%;}
#mc_embed_signup #mce-error-response {display:none;}
#mc_embed_signup #mce-success-response {color:#529214; display:none;}
#mc_embed_signup label.error {display:block; float:none; width:auto; margin-left:1.05em; text-align:left; padding:.5em 0;}

/* Mail Chimp Overrides - Contact Us Form*/
#mc_embed_contactus {
}
#mc_embed_contactus form {}
#mc_embed_contactus h2 {}
#mc_embed_contactus input {border-radius:0px}
#mc_embed_contactus input[type=checkbox]{-webkit-appearance:checkbox;}
#mc_embed_contactus input[type=radio]{-webkit-appearance:radio;}
#mc_embed_contactus input[type=email], input[type=text] {border:1px solid #d8d8d8; padding:0px 10px; 
/*border-radius:18px; -moz-border-radius:18px; -webkit-border-radius:18px; */}
#mc_embed_contactus input:focus {}
#mc_embed_contactus .button {
    }
#mc_embed_contactus .button:hover {}
#mc_embed_contactus .small-meta {}
#mc_embed_contactus .nowrap {}     
#mc_embed_contactus .clear {}

#mc_embed_contactus label {font-size:14px;}
#mc_embed_contactus input.email {}
#mc_embed_contactus .indicates-required {font-size:11px;}
#mc_embed_contactus .mc-field-group {padding-bottom:8px;}

#mc_embed_contactus input.button {width:120px;}

#mc_embed_contactus div#mce-responses {}
#mc_embed_contactus div.response {}
#mc_embed_contactus #mce-error-response {}
#mc_embed_contactus #mce-success-response {}
#mc_embed_contactus label.error {}

.index-price {
    position:absolute;
    background-color:#F8F8F8;
    z-index:10000;
    padding:3px 10px;
    left:0;
    margin-top: 55px;
    margin-left: -25px;
}
.sale-price {
    font-weight:bold;
    color:red;
}
.sale-oldprice {
    text-decoration: line-through;
}

#collection .more-info {
    font-size:24px;
    font-weight:bold;
}

.welcome-text {
    width:90%;
    padding-bottom:20px;
}

.welcome-text-border {
    border-top: 1px dotted #A1A1A1;
    width:96%;
}

.welcome-text h2{
}

.footer-top-break {
    border-top: 1px dotted #A1A1A1;
    width:96%;
    width: 1200px;
    margin: 0 auto;
}

.content2 {
    float:right;
    width:450px;
}

#collection .browse-title span{
    text-transform:none;
}

.clr {
    clear:both;
    height:5px;
}

.contactOnContact {
    font-size:16px;
}

/* Connect Bar */

#social_container{
    float: right;
    width: 220px;
    z-index:10000;
    height: 50px;
    margin: -2px 0 12px 10px;
}
.social_buttons {
    height: 30px;
    float: right;
    padding: 9px 0px 5px 0px;
    width: 100%;
}
.connect{
    position: relative;
    float: left;
    font-size: 21px;
    padding-left: 18px;
    padding-top: 4px;
}
.face_btn{
    background-image:url('http://cdn.shopify.com/s/files/1/0110/8432/files/fb_btn2.png?2676');
    background-repeat:no-repeat;
    padding: 0px 13px 15px 30px;
    position:relative;
    float: right;
}
.twit_btn{
    background-image:url('http://cdn.shopify.com/s/files/1/0110/8432/files/tw_btn2.png?2676');
    background-repeat:no-repeat;
    padding: 0px 5px 15px 30px;
    position:relative;
    float: right;
}

/* Contact Page Changes */

#mce-MMERGE2 {
    width:850px;
}

#mce-EMAIL, #mce-FNAME {
    width: 200px;
}

.contact-us-border {
    border-top: 1px dotted #A1A1A1;
    width: 840px;
}